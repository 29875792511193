var view_model, vm, WP_VARS;

(function($){
  'use strict';
  window.skafold = $.extend(true, {}, window.skafold || {}, {

    view_model: null,

    $header: $('.navbar-header'),
    $search_desktop: $('#search-desktop'),
    $search_mobile: $('#search-mobile'),
    $search_results: $('#search-results'),
    $match: $('.match-height'),
    $masonry: $('.masonry'),
    $form_control: $('.form-control'),
    $carousel: $('.carousel'),
    $news_carousel: $('.news-carousel'),
    $maps: $('.acf-map'),
    $wind: $( window ),

    init: function() {
      this.data_binding();
      this.search();
      this.carousel();
      this.header();
      this.labels();
      this.maps();
      this.match_height();
      this.masonry();
      this.gravityform_show_message();
      this.gravityform_primative_conditionals();
      this.magnificPopup();
    },

    pad: function(number, digits) {
      return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
    },

    data_binding: function() {
      var model = {
        is_overlay: ko.observable(false),
        is_search: ko.observable(false),
        is_searching: ko.observable(false),
        is_menu: ko.observable(false)
      };

      window.skafold.view_model = ko.mapping.fromJS(model);

      window.skafold.view_model.body_class = function() {
        var classes = [];
        if ( this.is_overlay() ) classes.push('js-overlay');
        if ( this.is_menu() ) classes.push('js-menu');
        if ( this.is_search() ) classes.push('js-search');
        if ( this.is_searching() ) classes.push('js-searching');
        return classes.join( ' ' );
      };

      window.skafold.view_model.overlay = function( state ) {
        if ( typeof state != 'boolean' ) state = false;
        this.is_overlay(false);
        this.is_menu(state);
        this.is_search(false);
        carousel_resize();
      };

      window.skafold.view_model.search = function( state ) {
        if ( typeof state != 'boolean' ) state = !this.is_overlay();
        if ( state ) {
          window.skafold.$search_desktop.val('').trigger('keyup');
          window.skafold.$search_mobile.val('').trigger('keyup');
          window.skafold.$search_results.html('');
        }
        this.is_overlay(state);
        this.is_search(state);
        carousel_resize();
        if( window.skafold.$wind.innerWidth() <= 767 ) {
          window.skafold.$search_mobile.focus();
        } else {
          setTimeout( function() {
            window.skafold.$search_desktop.focus();
          }, 300 );
        }
      };

      window.skafold.view_model.searching = function( state ) {
        if ( typeof state != 'boolean' ) state = true;
        this.is_searching(state);
      };

      window.skafold.view_model.menu = function( state ) {
        if ( typeof state != 'boolean' ) state = !this.is_overlay();
        this.is_overlay(state);
        this.is_menu(state);
        carousel_resize();
      };

      function carousel_resize() {
        setTimeout( function() {
          $('.carousel').slick('setPosition');
        }, 50 );
      };

      ko.applyBindings(window.skafold.view_model);
    },

    search: function() {
      function do_search($elem) {
        window.skafold.view_model.searching();
        $.ajax({
          url: WP_VARS.ajaxurl, // '/wp/wp-admin/admin-ajax.php',
          type: 'POST',
          dataType: 'json',
          data: {
            action: 'search',
            s: $elem.val()
          },
          success: function(response) {
            window.skafold.$search_results.html(response.results);
          },
          complete: function() {
            window.skafold.view_model.searching(false);
          }
        });
      }
      window.skafold.$search_desktop.closest( 'form' ).on( 'submit', function(e) {
        e.preventDefault();
        do_search( window.skafold.$search_desktop );
      });
      window.skafold.$search_mobile.closest( 'form' ).on( 'submit', function(e) {
        e.preventDefault();
        do_search( window.skafold.$search_mobile );
      });
      window.skafold.$search_desktop
        .bind('hastext', function () {
          var $this = $(this);
              $this.closest( '.form-group' ).addClass( 'js-has-value' ).removeClass( 'js-no-value' );
        } )
        .bind('textchange', function () {
          var $this = $(this),
              $label = $('[for="' + $this.attr('id') + '"]');
          if ( $this.val().length >= 3 ) {
            $this.closest( '.form-group' ).addClass( 'js-ready' );
            $label.text( $label.data( 'value' ) );
          } else {
            $this.closest( '.form-group' ).removeClass( 'js-ready' );
            $label.text( $label.data( 'novalue' ) );
          }
        } )
        .bind('notext', function () {
          var $this = $(this);
              $this.closest( '.form-group' ).addClass( 'js-no-value' ).removeClass( 'js-has-value' );
        } );
    },

    carousel: function() {
      window.skafold.$carousel.slick();

      /* News specific */

      if (window.skafold.$news_carousel.length) {
        window.skafold.$news_carousel.on('afterChange', function(event, slick, currentSlide, nextSlide){
          $('.news-current').text( window.skafold.pad( currentSlide+1, 2 ) );
        });
        $('.news-next').on('click', function() {
          window.skafold.$news_carousel.slick('slickNext');
        });
      }
    },

    labels: function() {
      window.skafold.$form_control
        .bind('hastext', function () {
          var $this = $(this);
              $this.addClass( 'js-has-value' ).removeClass( 'js-no-value' );
        } )
        .bind('textchange', function () {
          var $this = $(this);
        } )
        .bind('notext', function () {
          var $this = $(this);
              $this.addClass( 'js-no-value' ).removeClass( 'js-has-value' );
        } );
    },

    header: function() {
      function header() {
        var tollerance = window.skafold.$wind.innerWidth() <= 767 ? 20 : 60;
        if (window.skafold.$wind.scrollTop() >= tollerance ) {
          window.skafold.$header.addClass('js-float');
        } else {
          window.skafold.$header.removeClass('js-float');
        }
      };
      header();
      window.skafold.$wind.scroll(function() {
        header();
      });
    },

    maps: function() {

      function new_map( $el ) {

        var $markers = $el.find('.marker');
        var args = {
          zoom    : 14,
          center    : new google.maps.LatLng(0, 0),
          mapTypeId : google.maps.MapTypeId.ROADMAP,
          scrollwheel: false,
          navigationControl: false,
          mapTypeControl: false,
          scaleControl: false,
          draggable: true,
          styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"},{"visibility":"on"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"geometry.fill","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#b4d4e1"},{"visibility":"on"}]}]
        };

        var map = new google.maps.Map( $el[0], args);

        map.markers = [];

        $markers.each(function(){
            add_marker( $(this), map );
        });

        setTimeout( function() {
          center_map( map );
        }, 500 );

        return map;

      }

      function add_marker( $marker, map ) {

        var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

        var icon = {

            path: "M11.5,0C5.1,0,0,5.2,0,11.6C0,20.2,11.5,33,11.5,33S23,20.2,23,11.6C23,5.2,17.9,0,11.5,0z M11.5,15.7c-2.3,0-4.1-1.8-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1c2.3,0,4.1,1.8,4.1,4.1C15.6,13.8,13.8,15.7,11.5,15.7z",
            fillColor: 'transparent',
            fillOpacity: 1,
            anchor: new google.maps.Point(0,0),
            strokeWeight: 0,
            scale: 1
        }

        var marker = new google.maps.Marker({
          position  : latlng,
          icon: icon,
          map: map
        });

        map.markers.push( marker );

        // if marker contains HTML, add it to an infoWindow
        if( $marker.html() )
        {

          var infobox = new InfoBox({
               content: $marker.html(),
               disableAutoPan: false,
               maxWidth: 150,
               pixelOffset: new google.maps.Size(-140, 0),
               zIndex: null,
               boxStyle: {
                  background: '#ffffff',
                  opacity: 1,
                  width: '280px'
              },
              infoBoxClearance: new google.maps.Size(1, 1)
          });

          infobox.open(map, marker);

          // show info window when marker is clicked
          google.maps.event.addListener(marker, 'click', function() {
            infowindow.open( map, marker );
          });

        }

      }

      function center_map( map, offset_x, offset_y ) {

        var bounds = new google.maps.LatLngBounds();

        $.each( map.markers, function( i, marker ){
          var latlng = new google.maps.LatLng( marker.position.lat() + 0.0037196, marker.position.lng() );
          // var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
          bounds.extend( latlng );
        });

        if( map.markers.length == 1 ) {
            var center = bounds.getCenter();
            map.setCenter( center );
            map.setZoom( 14 );
        } else {
          map.fitBounds( bounds );
        }

      }

      var map = null;

      window.skafold.$maps.each(function(){
        map = new_map( $(this) );
      });

    },

    match_height: function() {
      if ( window.skafold.$match.length > 0 ) {
        var unique_match_keys = [];
        window.skafold.$match.each( function() {
          var $this = $(this);
          if (unique_match_keys.indexOf($this.data('match')) == -1 ) {
            unique_match_keys.push($this.data('match'));
          }
        });
        $( unique_match_keys ).each( function() {
          $( '.match-height[data-match="' + this + '"]' ).matchHeight();
        });
      }
    },

    masonry: function() {
      if ( window.skafold.$masonry.length > 0 ) {
        window.skafold.$masonry.masonry( {
          itemSelector: '.masonry-item'
        } );
      }
    },

    magnificPopup: function() {
      $('.popup-video').magnificPopup({
        disableOn: 0,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: true
      });
    },

    gravityform_show_message: function() {
      if( $("#gform_confirmation_wrapper_1").length > 0 ) {
        $('html, body').animate({
          scrollTop: $("#gform_confirmation_wrapper_1").offset().top
        }, 0);
      }
    },

    gravityform_primative_conditionals: function() {
      $( 'div[class^="conditional_toggle-"],div[class*=" conditional_toggle-"]' ).find('input[type="radio"], input[type="checkbox"]').on( 'change', function() {
        var $this = $(this).closest('div[class^="conditional_toggle-"],div[class*=" conditional_toggle-"]'),
            $input = $(this),
            input_name = $input.attr( 'name' ),
            trigger_class = $this.attr( 'class' ).split( ' ' ),
            toggle_class = null,
            condition_met = false;
        $( trigger_class ).each( function( i, value ) {
          if ( value.indexOf('conditional_toggle-') > -1 ) toggle_class = value.split( '-' )[1];
        } );
        var $input_active = $( 'input[name="' + $input.attr('name') + '"]:checked' ).eq(0);
        var $target = $( '.' + toggle_class );
        if ( $target.length == 0 ) return;
        if ( $input_active.length > 0 ) {
          if ( $( 'input[name="' + $input.attr('name') + '"]:checked' ).eq(0).val().replace('*', '').trim() == toggle_class ) condition_met = true;
        }
        if ( condition_met ) {
          $target.addClass( 'js-conditional' );
        } else {
          $target.removeClass( 'js-conditional' );
        }
      } );
    }

  });

})(jQuery);

jQuery(document).ready(function(){
  skafold.init();
});

