(function () {
    if (!Modernizr.input.placeholder) {
        $('[placeholder]').each(function () {
            var initial_value, input;
            input = $(this);
            initial_value = input.attr('placeholder');
            input.val(initial_value);
            input.focus(function () {
                if (input.val() === input.attr('placeholder')) {
                    return input.val('');
                }
            });
            return input.blur(function () {
                if (input.val() === '') {
                    return input.val(initial_value);
                }
            });
        });
    }
}.call(this));